import React, { Component } from "react";
import {
  Form,
  Input,
  Select,
  Button,
  AutoComplete,
  Radio,
  Card,
  message,
  Row,
  Col,
} from "antd";
import axios from "axios";
import "./style/Settings.css";

const { Option } = Select;

class Settings extends Component {
  state = {
    bugDetails: "",
    mail: "",
    name: "",
    page: "",
    Data: [],
  };

  componentDidMount() {
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/report-bug/all-reportbug`)
      .then((response) => {
        var Data = response.data.data.reverse();
        console.log("details", Data);
        this.setState({ Data });

        // var bData = blogData.reverse();
        // this.setState({ dataBlog: bData });
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });
  }

  handleSubmit = (bugDetails, name, mail, page) => {
    console.log("clicked", bugDetails, name, mail, page);

    axios
      .post(`${process.env.REACT_APP_API_URL}/api/report-bug/register`, {
        name: name,
        email: mail,
        issue: bugDetails,
        page: page,
      })
      .then((res) => {
        this.setState({});
        if (res) {
          message.success("Submitted Successfully");
        }
        window.location.reload(false);
      })
      .catch(function (error) {
        console.log(error);
        message.error("Error");
      });
  };
  handleDelete = (id) => {
    console.log("blog deleted");
    axios
      .delete(`${process.env.REACT_APP_API_URL}/api/report-bug/${id}`, {})
      .then((res) => {
        message.success("Deleted Successfully");

        window.location.reload(false);
      })
      .catch(function (error) {
        console.log(error);
        message.error("Error In Deleting");
      });
  };
  render() {
    return (
      <>
        <Card style={{ width: 1000, marginLeft: "10%" }}>
          <Form
            className="form-container"
            name="register"
            initialValues={{
              prefix: "91",
            }}
            scrollToFirstError
          >
            <Form.Item
              name="name"
              label="Name"
              rules={[
                {
                  required: true,
                  message: "Please input your name!",
                },
              ]}
            >
              <Input
                style={{ maxWidth: 750, marginLeft: "12%" }}
                value={this.state.name}
                onChange={(e) => this.setState({ name: e.target.value })}
              />
            </Form.Item>
            <Form.Item
              name="email"
              label="E-mail"
              rules={[
                {
                  type: "email",
                  message: "The input is not valid E-mail!",
                },
                {
                  required: true,
                  message: "Please input your E-mail!",
                },
              ]}
            >
              <Input
                style={{ maxWidth: 750, marginLeft: "12%" }}
                value={this.state.mail}
                onChange={(e) => this.setState({ mail: e.target.value })}
              />
            </Form.Item>

            <Form.Item
              name="select"
              label="Bug Encountered on"
              hasFeedback
              rules={[
                {
                  required: true,
                  message: "Please select where has been encounterd!",
                },
              ]}
            >
              <Select
                style={{ maxWidth: 750, marginLeft: "2%" }}
                placeholder="Select"
                onChange={(value) => this.setState({ page: value })}
              >
                <Option value="products">Products</Option>
                <Option value="orders">Orders</Option>
                <Option value="live prices">Live Prices</Option>
                <Option value="discount">Discount</Option>
                <Option value="user">User</Option>
              </Select>
            </Form.Item>

            <Form.Item
              name="error"
              label="Bug Details"
              placeholder="Please explain the bug you are getting"
              rules={[
                {
                  required: true,
                  message: "Please explain the bug you are getting!",
                },
              ]}
            >
              <Input
                style={{ maxWidth: 750, marginLeft: "8.7%" }}
                value={this.state.bugDetails}
                onChange={(e) => this.setState({ bugDetails: e.target.value })}
              />
            </Form.Item>

            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                onClick={() =>
                  this.handleSubmit(
                    this.state.bugDetails,
                    this.state.name,
                    this.state.mail,
                    this.state.page
                  )
                }
              >
                submit
              </Button>
            </Form.Item>
          </Form>
        </Card>
        <div>
          <p style={{ fontSize: 30, marginTop: 10, marginBottom: 10 }}>
            Generated Queries
          </p>
          <Row>
            {this.state.Data.map((data) => (
              <Col span={8}>
                <Card style={{ width: 400, marginBottom: 20 }}>
                  <p>Name: {data.name}</p>
                  <p>Email: {data.email}</p>
                  <p>Page: {data.page}</p>
                  <p>Issue: {data.issue}</p>
                  <Button
                    type="primary"
                    danger
                    style={{}}
                    onClick={() => this.handleDelete(data._id)}
                  >
                    Delete
                  </Button>
                </Card>
              </Col>
            ))}
          </Row>
        </div>
      </>
    );
  }
}
export default Settings;
