import React from 'react'
import { Col, Row } from 'antd'
import './style/Blog.css'
import CKEditor from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
class Blogpage extends React.Component {
  state = { dismiss: false }

  render() {
    return (
      <div>
        <Row>
          <Col span={24}>
            {/* <CKEditor
              editor={ClassicEditor}
              onInit={editor => {
                // You can store the "editor" and use when it is needed.
                console.log('Editor is ready to use!', editor)
              }}
              onChange={(event, editor) => {
                const data = editor.getData()
                console.log({ event, editor, data })
              }}
              onBlur={(event, editor) => {
                console.log('Blur.', editor)
              }}
              onFocus={(event, editor) => {
                console.log('Focus.', editor)
              }}
            /> */}
          </Col>
        </Row>
      </div>
    )
  }
}

export default Blogpage
