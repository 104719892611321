import React from "react";
import { Redirect } from 'react-router-dom'
import "./style/Login.css";
import { Row, Col, Card, Input, Space, Button, message } from 'antd';
import {
    UserOutlined,
    EyeInvisibleOutlined,
    EyeTwoTone
} from '@ant-design/icons';




class Login extends React.Component {
    state = {
        user_id: '',
        user_password: '',
        redirect: false
    }


    renderRedirect = () => {
        if (this.state.redirect) {

            return <Redirect to='/dashboard' />
        }
    }

    logIn = () => {

        console.log('email and password', this.state.user_id, this.state.user_password)
        if (this.state.user_id === 'ludo@directgold.com') {
            if (this.state.user_password === '123Directgold') {
                this.setState({
                    redirect: true
                })
                console.log('redirect to dashboard')
                message.success('Logged In');
            }
            else {
                message.error('Incorrect Password');
            }
        }
        else {
            message.error('Incorrect User Name');
        }
    }
    render() {
        return (
            <Row justify="center">
                <Col span={16}>col</Col>
                <Col span={8}>
                    <Card className="login-card">
                        <Space direction="vertical">
                            <Input
                                size="large"
                                placeholder="Username"
                                value={this.state.user_id}
                                onChange={e => this.setState({ user_id: e.target.value })}
                                prefix={<UserOutlined />}
                            />
                            <Input.Password
                                size="large"
                                placeholder="Password"
                                value={this.state.user_password}
                                onChange={e => this.setState({ user_password: e.target.value })}
                                iconRender={visible =>
                                    visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                                }
                            />
                            <Button type="primary" onClick={this.logIn} >Log In</Button>
                            <Button type="link" block>
                                Trouble LogIn
                    </Button>
                            {this.renderRedirect()}
                        </Space>
                    </Card>
                </Col>
            </Row>
        );
    }
}

export default Login;