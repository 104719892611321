import React from 'react'
import { Card, Input, Button } from 'antd'
import { Col, Row } from 'antd'
import './style/Blog.css'
import {
  FontColorsOutlined,
  PaperClipOutlined,
  SmileOutlined,
  CopyOutlined,
  EditOutlined
} from '@ant-design/icons'
import FloatingMenu from './FloatingMenu'

const { TextArea } = Input

class Blog extends React.Component {
  state = { dismiss: false }

  render() {
    return (
      <div>
        <Row>
          <Col span={16}>
            <Card
              title="New Message"
              className={
                this.state.dismiss
                  ? 'box-container-invisible'
                  : 'box-container-visible'
              }
              extra={
                <i
                  onClick={() => this.setState({ dismiss: true })}
                  class="dismiss fas fa-times"
                ></i>
              }
              style={{ width: 'auto' }}
              headStyle={{ backgroundColor: '#393e46', color: 'white' }}
            >
              <Input className="blog-input" placeholder="To" />
              <Input className="blog-input" placeholder="Subject" />
              <TextArea className="blog-input-textarea" rows={13} />
              <Button type="primary">Send</Button>
              <FontColorsOutlined className="blog-icon" />
              <PaperClipOutlined className="blog-icon" />
              <SmileOutlined className="blog-icon" />
              <CopyOutlined className="blog-icon" />
              <EditOutlined className="blog-icon" />
            </Card>
          </Col>
          <FloatingMenu />
        </Row>
      </div>
    )
  }
}

export default Blog
