import React, { Component } from "react";
import "./style/BlogLayout.css";
import { Layout, Menu, Breadcrumb } from "antd";
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  AppstoreAddOutlined,
  LaptopOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import Menubar from "../common/Menubar";
import Settings from "../Settings/Settings";
import Newsletter from "./Newsletter";
import Blogpage from "./Blogpage";
import Ck from "./Ck";

const { Sider } = Layout;

class BlogLayout extends Component {
  constructor(props) {
    super(props);

    this.state = {
      collapsed: false,
      clicked: "Ck",
    };

    this.component = null;
  }

  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };

  render() {
    switch (this.state.clicked) {
      case "Blogs":
        this.component = <Blogpage />;
        break;

      case "Newsletter":
        this.component = <Newsletter />;
        break;

      case "Ck":
        this.component = <Ck />;
        break;

      case "Settings":
        this.component = <Settings />;
        break;

      default:
        break;
    }

    return (
      <>
        <Layout style={{ minHeight: "100vh" }}>
          <Menubar />
          <Layout style={{ paddingTop: "64px" }}>
            {/* change */}
            <Sider
              trigger={null}
              collapsible
              collapsed={this.state.collapsed}
              width={200}
              className="site-layout-background"
              style={{
                overflow: "auto",
                height: "100%",
                position: "fixed",
                left: 0,
              }}
            >
              {/* change */}
              <Menu
                theme="dark"
                mode="inline"
                defaultSelectedKeys={["sub1"]}
                defaultOpenKeys={["sub1"]}
                style={{ height: "100%", borderRight: 0 }}
              >
                {/* <Menu.Item
                  key="sub1"
                  onClick={() => this.setState({ clicked: "Newsletter" })}
                  icon={<AppstoreAddOutlined />}
                >
                  Newsletter
                </Menu.Item> */}

                <Menu.Item
                  key="sub3"
                  onClick={() => this.setState({ clicked: "Ck" })}
                  icon={<LaptopOutlined />}
                >
                  Blogs
                </Menu.Item>
                {/* <Menu.Item
                  key="sub4"
                  onClick={() => this.setState({ clicked: "Settings" })}
                  icon={<SettingOutlined />}
                >
                  Settings
                </Menu.Item> */}
              </Menu>
            </Sider>
            <Layout
              style={
                this.state.collapsed
                  ? { padding: "0 24px 24px", marginLeft: 80 }
                  : { padding: "0 24px 24px", marginLeft: 200 }
              }
            >
              {/* change */}
              <Breadcrumb style={{ margin: "16px 0" }}>
                {React.createElement(
                  this.state.collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
                  {
                    className: "trigger",
                    onClick: this.toggle,
                  }
                )}
                <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
                <Breadcrumb.Item>{this.state.clicked}</Breadcrumb.Item>
              </Breadcrumb>

              {this.component}
            </Layout>
          </Layout>
        </Layout>
      </>
    );
  }
}

export default BlogLayout;
