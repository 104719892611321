import React from 'react'
import { Button } from 'antd'
import { Card, Col, Row } from 'antd'
import { Input } from 'antd'

class Discount extends React.Component {
  render() {
    return (
      <div className="site-card-wrapper">
        <Row gutter={16}>
          <Col span={8}>
            <Card
              title="Generate a New Coupon"
              bordered={false}
              style={{
                width: 350,
                height: 'auto',
                marginLeft: 75,
                marginTop: 100
              }}
            >
              <p>Create Coupon Code: </p>
              <p>
                {' '}
                <Input placeholder="Enter Code Here" style={{ width: 300 }} />
              </p>
              <br></br>
              <p>Product Id/Name:</p>
              <p>
                {' '}
                <Input
                  placeholder="Enter Product Id/Name "
                  style={{ width: 300 }}
                />
              </p>
              <p>
                {' '}
                <Button type="primary" style={{ marginTop: 10 }}>
                  Submit
                </Button>
              </p>
            </Card>
          </Col>
          <Col span={16}>
            <h2
              style={{
                textAlign: 'center',
                paddingBottom: 30,
                borderLeftWidth: 1,
                borderLeftStyle: 'ridge',
                borderColor: 'darkgrey',
                marginLeft: 100,
                marginBottom: 0
              }}
            >
              Generated Coupons
            </h2>
            <Row
              gutter={16}
              style={{
                marginLeft: 100,
                marginRight: 50,
                borderLeftWidth: 1,
                borderLeftStyle: 'ridge',
                borderColor: 'darkgrey',
                paddingLeft: 55
              }}
            >
              <Col span={12}>
                <Card
                  title="Coupon generated on xx/xx/xx"
                  bordered={false}
                  style={{
                    width: 300,
                    height: 'auto'
                  }}
                >
                  <p>Coupon Code: abc </p>

                  <br></br>
                  <p>Product Id/Name: pqr</p>

                  <p>
                    {' '}
                    <Button type="primary" style={{ marginTop: 10 }}>
                      Withdraw
                    </Button>
                  </p>
                </Card>
              </Col>
              <Col span={8}>
                <Card
                  title="Coupon generated on xx/xx/xx"
                  bordered={false}
                  style={{ width: 300, height: 'auto' }}
                >
                  <p>Coupon Code: abc </p>

                  <br></br>
                  <p>Product Id/Name: pqr</p>

                  <p>
                    {' '}
                    <Button type="primary" style={{ marginTop: 10 }}>
                      Withdraw
                    </Button>
                  </p>
                </Card>
              </Col>
            </Row>
            <Row
              gutter={16}
              style={{
                marginLeft: 100,
                marginRight: 50,
                paddingTop: 20,
                borderLeftWidth: 1,
                borderLeftStyle: 'ridge',
                borderColor: 'darkgrey',
                paddingLeft: 55
              }}
            >
              <Col span={12}>
                <Card
                  title="Coupon generated on xx/xx/xx"
                  bordered={false}
                  style={{ width: 300, height: 'auto' }}
                >
                  <p>Coupon Code: abc </p>

                  <br></br>
                  <p>Product Id/Name: pqr</p>

                  <p>
                    {' '}
                    <Button type="primary" style={{ marginTop: 10 }}>
                      Withdraw
                    </Button>
                  </p>
                </Card>
              </Col>
              <Col span={8}>
                <Card
                  title="Coupon generated on xx/xx/xx"
                  bordered={false}
                  style={{ width: 300, height: 'auto' }}
                >
                  <p>Coupon Code: abc </p>

                  <br></br>
                  <p>Product Id/Name: pqr</p>

                  <p>
                    {' '}
                    <Button type="primary" style={{ marginTop: 10 }}>
                      Withdraw
                    </Button>
                  </p>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    )
  }
}

export default Discount
