import React from 'react'
import { Card } from 'antd'
import { Col, Row } from 'antd'
import './style/Blog.css'
// import 'boxicons'

import {
  YoutubeOutlined,
  BorderInnerOutlined,
  AlignLeftOutlined,
  ColumnHeightOutlined,
  MediumOutlined,
  PictureOutlined,
  VerticalAlignMiddleOutlined,
  DribbbleOutlined,
  EnvironmentOutlined,
  SelectOutlined
} from '@ant-design/icons'

class FloatingMenu extends React.Component {
  state = { dismiss: false }

  render() {
    return (
      <Col
        span={8}
        className="elementor"
        style={{
          transform: 'scale(0.9)',
          top: 95,
          left: 1080,
          position: 'fixed',
          width: 400,
          backgroundColor: 'rgb(22, 22, 22)',
          paddingTop: 10,
          paddingBottom: 10,
          paddingLeft: 5,
          paddingRight: 5
        }}
      >
        <Row>
          <Col span={12}>
            <Card
              className="elmtcard"
              bordered={false}
              style={{
                width: 'auto',
                height: 'auto',
                textAlign: 'center'
              }}
            >
              <p>
                <YoutubeOutlined style={{ transform: 'scale(2)' }} />
              </p>
              <p>Video</p>
            </Card>
          </Col>
          <Col span={12}>
            <Card
              className="elmtcard"
              bordered={false}
              style={{
                width: 'auto',
                height: 'auto',
                textAlign: 'center'
              }}
            >
              <p>
                <BorderInnerOutlined style={{ transform: 'scale(2)' }} />
              </p>
              <p>Inner Section</p>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <Card
              className="elmtcard"
              bordered={false}
              style={{
                width: 'auto',
                height: 'auto',
                textAlign: 'center'
              }}
            >
              <p>
                <AlignLeftOutlined style={{ transform: 'scale(2)' }} />
              </p>
              <p>Text Editor</p>
            </Card>
          </Col>
          <Col span={12}>
            <Card
              className="elmtcard"
              bordered={false}
              style={{
                width: 'auto',
                height: 'auto',
                textAlign: 'center'
              }}
            >
              <p>
                <ColumnHeightOutlined style={{ transform: 'scale(2)' }} />
              </p>
              <p>Spacer</p>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <Card
              className="elmtcard"
              bordered={false}
              style={{
                width: 'auto',
                height: 'auto',
                textAlign: 'center'
              }}
            >
              <p>
                <MediumOutlined style={{ transform: 'scale(2)' }} />
              </p>
              <p>Heading</p>
            </Card>
          </Col>
          <Col span={12}>
            <Card
              className="elmtcard"
              bordered={false}
              style={{
                width: 'auto',
                height: 'auto',
                textAlign: 'center'
              }}
            >
              <p>
                <PictureOutlined style={{ transform: 'scale(2)' }} />
              </p>
              <p>Image</p>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <Card
              className="elmtcard"
              bordered={false}
              style={{
                width: 'auto',
                height: 'auto',
                textAlign: 'center'
              }}
            >
              <p>
                <VerticalAlignMiddleOutlined
                  style={{ transform: 'scale(2)' }}
                />
              </p>
              <p>Divider</p>
            </Card>
          </Col>
          <Col span={12}>
            <Card
              className="elmtcard"
              bordered={false}
              style={{
                width: 'auto',
                height: 'auto',
                textAlign: 'center'
              }}
            >
              <p>
                <DribbbleOutlined style={{ transform: 'scale(2)' }} />
              </p>
              <p>Icons</p>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <Card
              className="elmtcard"
              bordered={false}
              style={{
                width: 'auto',
                height: 'auto',
                textAlign: 'center'
              }}
            >
              <p>
                <SelectOutlined style={{ transform: 'scale(2)' }} />
              </p>
              <p>Button</p>
            </Card>
          </Col>
          <Col span={12}>
            <Card
              className="elmtcard"
              bordered={false}
              style={{
                width: 'auto',
                height: 'auto',
                textAlign: 'center'
              }}
            >
              <p>
                <EnvironmentOutlined style={{ transform: 'scale(2)' }} />
              </p>
              <p>Google Maps</p>
            </Card>
          </Col>
        </Row>
      </Col>
    )
  }
}

export default FloatingMenu
