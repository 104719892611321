import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import { Layout, Menu, Dropdown, Button } from 'antd'
import { UserOutlined, LogoutOutlined, DownOutlined } from '@ant-design/icons'
import { NavLink, withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'

const { Header } = Layout

const men = (
  <Menu style={{ textAlign: 'center' }}>
    <Menu.Item>
      <a target="_blank" rel="noopener noreferrer" href="javascript(void)">
        <img
          src={require('../images/userAcc.png')}
          className="userAcc"
          style={{ width: 80, borderRadius: 15 }}
          alt="user-img"
        />
      </a>
    </Menu.Item>
    <Menu.Item>
      <a target="_blank" rel="noopener noreferrer" href="javascript(void)">
        ludo@directgold.com
      </a>
    </Menu.Item>
    {/* <Menu.Item>
      <a target="_blank" rel="noopener noreferrer" href="javascript(void)">
        Email
      </a>
    </Menu.Item> */}
    {/* <Menu.Item danger>Change Password</Menu.Item> */}
  </Menu>
)

export class Menubar extends Component {
  static propTypes = {
    location: PropTypes.object.isRequired
  }

  renderRedirect = () => {
    if (this.state.redirect) {

      return <Redirect to='/' />
    }
  }
  state = {
    redirect: false
  }
  logout = () => {
    this.setState({
      redirect: true
    })
  }
  render() {
    const { location } = this.props
    return (
      <>
        <Header
          className="header"
          style={{ position: 'fixed', zIndex: 1, width: '100%' }}
        >
          {/* change */}
          <div className="logo" />
          <Menu
            theme="dark"
            mode="horizontal"
            defaultSelectedKeys={['/dashboard']}
            selectedKeys={[location.pathname]}
          >
            <Menu.Item key="/dashboard">
              <NavLink to="/dashboard">Direct Gold</NavLink>
            </Menu.Item>
            <Menu.Item key="/blogs">
              <NavLink to="/blogs">Blog</NavLink>
            </Menu.Item>
            <Menu.Item
              key="3"
              style={{ float: 'right' }}
              icon={<LogoutOutlined />}
            >
              <Button onClick={this.logout} type="link">Logout</Button>
              {this.renderRedirect()}
            </Menu.Item>
            <Menu.Item
              key="4"
              style={{ float: 'right' }}
              icon={<UserOutlined />}
            >
              <Dropdown overlay={men}>
                <a
                  href="javascript(void)"
                  className="ant-dropdown-link"
                  onClick={e => e.preventDefault()}
                >
                  Account <DownOutlined />
                </a>
              </Dropdown>
            </Menu.Item>
          </Menu>
        </Header>
      </>
    )
  }
}

export default withRouter(Menubar)
