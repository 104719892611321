import React, { Component } from 'react'
import './style/NavigationBoard.css'
import { Link } from 'react-router-dom'
import { Layout, Col, Row } from 'antd'
import CustomCard from './common/Card'

class NavigationBoard extends Component {
  render() {
    return (
      <Layout
        style={{
          padding: '5% 10%',
          margin: 0,
          minHeight: 280
        }}
      >
        <Row gutter={[40, 40]}>
          <Col span={8}>
            <Link to="/dashboard">
              <CustomCard />
            </Link>
          </Col>
          <Col span={8}>
            <Link to="/dashboard">
              <CustomCard />
            </Link>
          </Col>
          <Col span={8}>
            <Link to="/dashboard">
              <CustomCard />
            </Link>
          </Col>
        </Row>
        <Row gutter={[40, 40]}>
          <Col span={8}>
            <Link to="/dashboard">
              <CustomCard />
            </Link>
          </Col>
          <Col span={8}>
            <Link to="/dashboard">
              <CustomCard />
            </Link>
          </Col>
          <Col span={8}>
            <Link to="/dashboard">
              <CustomCard />
            </Link>
          </Col>
        </Row>
      </Layout>
    )
  }
}

export default NavigationBoard
