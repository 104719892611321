import React from 'react'
import './style/OrderDetails.css'
import {
  Button,
  Tag,
  Modal,
  Row,
  Col,
  Steps,
  Skeleton,
  message,
  Radio,
  Card,
  Form,
  Space,
  Pagination,
} from 'antd'
import axios from 'axios'
import Lightbox from '../Product/lib'
import moment from 'moment'

const { Step } = Steps

class Order extends React.Component {
  state = {
    dismiss: false,
    loading: true,
    latestOrder: [],
    visible_track_order: false,
    visible_details_order: false,
    delivery_status: 0,
    modal_order: {},
    modal_orderid: '',
    modal_userid: '',
    modal_totalamount: '',
    modal_productname: '',
    modal_productid: '',
    modal_paymentmode: '',
    modal_name: '',
    modal_email: '',
    modal_imageURL: '',
    modal_deliverystatus: '',
    modal_address: '',
  }

  renderSkeleton = () => {
    return (
      <>
        <Skeleton loading={this.state.loading} active />
        <Skeleton loading={this.state.loading} active />
        <Skeleton loading={this.state.loading} active />
        <Skeleton loading={this.state.loading} active />
      </>
    )
  }

  componentDidMount() {
    axios.get(`${process.env.REACT_APP_API_URL}/api/order/all`).then((res) => {
      const order = res.data
      const orderData = order.data
      const latestOrder = orderData.reverse()
      console.log(latestOrder)
      this.setState({
        latestOrder,
        loading: false,
      })
    })
  }

  showTrackingModal = (order) => {
    this.setState({
      visible_details_order: false,
      visible_track_order: true,
      modal_orderid: order._id,
      modal_userid: order.userid,
      modal_productid: order.productid,
    })
  }

  showDetailsModal = (order) => {
    this.setState({
      visible_details_order: true,
      modal_order: order,
      modal_orderid: order._id,
      modal_userid: order.userid,
      modal_totalamount: order.totalamount,
      modal_productname: order.productname,
      modal_productid: order.productid,
      modal_paymentmode: order.paymentmode,
      modal_name: order.name,
      modal_email: order.email,
      modal_imageURL: order.imageURL[0],
      modal_deliverystatus: order.deliverystatus,
      modal_address: order.address,
      // modal_createdAt: order.createdAt,
      // modal_updatedAt: order.updatedAt,
    })
  }

  handleTrackingCancel = (e) => {
    console.log(e)
    this.setState({
      visible_track_order: false,
    })
  }

  handleDetailsCancel = (e) => {
    console.log(e)
    this.setState({
      visible_details_order: false,
    })
  }

  renderOrders() {
    return this.state.latestOrder.map((order) => {
      return (
        <Card
          className={
            this.state.dismiss
              ? 'box-container-invisible'
              : 'box-container-visible'
          }
          title="Order Details"
          extra={
            <i
              onClick={() => this.setState({ dismiss: true })}
              class="dismiss fas fa-times"
            ></i>
          }
        >
          <div class="card-content">
            <div class="user-details">
              <div class="name">{order.name}</div>
              <div class="data">
                <span class="card-label-sml">Order Id: </span>
                {order.orderId} | {moment(order.createdAt).format('DD-MM-YYYY')}
              </div>
              <div class="data">
                <span class="card-label-sml">Email: </span>
                {order.email}
              </div>
              <div className="data">
                <span class="card-label-sml">User Phone: </span> {order.phone}
              </div>
              <div class="data">
                <span class="card-label-sml">Deliver to: </span>
                {order.address}
              </div>
              <div class="data">
                <span class="card-label-sml">Payment Mode: </span> Online
              </div>
            </div>
            <div class="user-details2">
              <div class="name">{order.productname}</div>
              <div className="data">
                <Row
                  style={{
                    border: '1px solid #e1e1e1',
                    padding: '10px',
                    textAlign: 'center',
                  }}
                >
                  <Col span={12}>
                    <h3>Products</h3>
                    {order.products.map((product) => (
                      <>
                        {' '}
                        <br /> <h4>{product.product.title}</h4>
                      </>
                    ))}
                  </Col>
                  <Col style={{ borderLeft: '1px solid #000' }} span={12}>
                    <h3>Quantity</h3>
                    {order.products.map((product) => (
                      <>
                        {' '}
                        <br /> <h4>{product.quantity}</h4>
                      </>
                    ))}
                  </Col>
                </Row>
              </div>
            </div>
          </div>
          <div class="order">
            <div class="button">
              <Button
                size={'large'}
                className="accept action-btn"
                onClick={() => this.showTrackingModal(order)}
              >
                <i class="fas fa-truck fa-icon"></i>Track Order
              </Button>
              <Button
                size={'large'}
                className="decline action-btn"
                onClick={() => this.showDetailsModal(order)}
              >
                <i class="fas fa-info-circle fa-icon"></i>More Details
              </Button>
            </div>
            <div class="total">Total: {order.totalprice}</div>
          </div>
        </Card>
      )
    })
  }

  render() {
    return (
      <>
        {this.state.loading ? this.renderSkeleton() : this.renderOrders()}

        {/* Track Order Modal */}
        <Modal
          title="Track Order"
          centered
          visible={this.state.visible_track_order}
          onCancel={this.handleTrackingCancel}
          footer={[null]}
        >
          <Row justify="center">
            <Col span={12}>
              <Steps direction="vertical" current={this.state.delivery_status}>
                <Step
                  title="Order Placed"
                  description="This is a description."
                />
                <Step
                  title="Order Received"
                  description="This is a description."
                />
                <Step
                  title="Order Shipped"
                  description="This is a description."
                />
                <Step
                  title="Order Delivered"
                  description="This is a description."
                />
              </Steps>
            </Col>
            <Col span={12}>
              <h2 className="track-heading">Order Status</h2>
              <p className="track-orderid">
                Order Id: {this.state.modal_orderid}
              </p>
              <p className="track-orderid">
                User Id: {this.state.modal_userid}
              </p>
              <p className="track-orderid">
                Product Id: {this.state.modal_productid}
              </p>
              <p className="track-para">Confirm the status of the order</p>

              <Form>
                <Form.Item name="radio-button">
                  <Radio.Group>
                    <Space direction="horizontal">
                      <Radio.Button
                        onClick={() => this.setState({ delivery_status: 1 })}
                        value="a"
                      >
                        Received
                      </Radio.Button>
                      <Radio.Button
                        onClick={() => this.setState({ delivery_status: 0 })}
                        value="b"
                      >
                        Not Received
                      </Radio.Button>
                    </Space>
                  </Radio.Group>
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </Modal>

        {/* More Details Modal */}
        <Modal
          title="Order Details"
          centered
          width={1400}
          visible={this.state.visible_details_order}
          onCancel={this.handleDetailsCancel}
          className="order-modal"
          footer={[null]}
        >
          <Row>
            <Col className="more-details-col" span={8}>
              <div>
                <h1 className="order-modal-title">Product Details</h1>
              </div>
              <div>
                <h2 className="order-modal-title">
                  {this.state.modal_productname}
                </h2>
              </div>
              <div>
                <h2 className="order-modal-subtitle">
                  <span class="modal-bold">Order Id: </span>
                  {this.state.modal_orderid}
                </h2>
              </div>
              <div>
                <h2 className="order-modal-subtitle">
                  <span class="modal-bold">Price: </span>
                  {this.state.modal_totalamount}
                </h2>
              </div>
              <div>
                <h2 className="order-modal-subtitle">
                  <span class="modal-bold">Payment Mode: </span>
                  {this.state.modal_paymentmode}
                </h2>
              </div>
              <div>
                <h2 className="order-modal-subtitle">
                  <span class="modal-bold">Delivery Status: </span>
                  {this.state.modal_deliverystatus}
                </h2>
              </div>
            </Col>
            <Col className="more-details-col" span={8}>
              <div>
                <h1 className="order-modal-title">User Details</h1>
              </div>
              <div>
                <h2 className="order-modal-title">{this.state.modal_name}</h2>
              </div>
              <div>
                <h2 className="order-modal-subtitle">
                  <span class="modal-bold">User Id: </span>
                  {this.state.modal_userid}
                </h2>
              </div>
              <div>
                <h2 className="order-modal-subtitle">
                  <span class="modal-bold">Email: </span>
                  {this.state.modal_email}
                </h2>
              </div>
              <div>
                <h2 className="order-modal-subtitle">
                  <span class="modal-bold">Delivery to: </span>
                  {this.state.modal_address}
                </h2>
              </div>
              <div>
                <Button
                  size={'large'}
                  className="accept action-btn"
                  onClick={() => this.showTrackingModal(this.state.modal_order)}
                >
                  <i class="fas fa-truck fa-icon"></i>Track Order
                </Button>
              </div>
            </Col>
            <Col span={8}>
              <div
                onClick={() => this.setState({ visible_details_order: false })}
              >
                <Lightbox
                  thumbnailWidth="450px"
                  thumbnailHeight="300px"
                  images={[
                    {
                      src: `${this.state.modal_imageURL}`,
                      title: this.state.modal_name,
                      description: 'Order ID: ' + this.state.modal_userid,
                    },
                  ]}
                />
                <Pagination
                  style={{ marginTop: '2%' }}
                  defaultCurrent={1}
                  total={3}
                />
              </div>
            </Col>
          </Row>
        </Modal>
      </>
    )
  }
}

export default Order
