import React from "react";
import "./style/LandingPage.css";
import ParticleComponent from "./Particles/ParticleComponent";
import Login from "./Login";

class LandingPage extends React.Component
{
    render()
    {
        return (
            <div className="landing-container">
            <ParticleComponent />
            <Login />
            </div>
        );
    }
}

export default LandingPage;
