import React from "react";
import { Table, Tag, Space } from "antd";

const TabularData = (props) => {
  const columns = [
    {
      title: "Weight (g)",
      key: "weight",
      dataIndex: "weight",
    },
    {
      title: "Price (€)",
      dataIndex: "price",
      key: "price",
    },
  ];

  const myData = []
  for (let i = 1; i < 1001; i = i * 10) {
    myData.push({
      weight: i,
      price:
        props.metal_type === "gold"
          ? props.goldInput * i
          : props.silverInput * i,
    });
  }
  console.log(myData);

  return (
    <Table
      columns={columns}
      dataSource={myData}
      pagination={{ pquantitySize: 10 }}
      scroll={{ y: 240 }}
    />
  );
};

export default TabularData;
