import React from "react";
import {
  Col,
  Row,
  message,
  Card,
  Button,
  Modal,
  Tabs,
  Upload,
  Form,
} from "antd";
import "./style/Blog.css";
import "./style/ck.css";
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import axios from "axios";
import { PlusOutlined } from "@ant-design/icons";

// Tabs
const { TabPane } = Tabs;
function callback(key) {
  console.log(key);
}

class Ck extends React.Component {
  state = {
    dismiss: false,
    blogData: "",
    dataBlog: [],
    visible: false,
    files: [],
    imgdata: [],
    ckdata: "",
    title: "",
    featuredImg: "",
  };

  // modal
  showModal = () => {
    this.setState({
      visible: true,
    });
  };
  showModalImage = () => {
    this.setState({
      visible_image: true,
    });
  };
  showModalUpload = () => {
    this.setState({
      visible_upload: true,
    });
  };

  handleOk = (e) => {
    console.log(e);
    this.setState({
      visible: false,
      visible_image: false,
      visible_upload: false,
    });
  };

  handleCancelModal = (e) => {
    console.log(e);
    this.setState({
      visible: false,
      visible_image: false,
      visible_upload: false,
    });
  };
  uploadFormDetails = (e) => {
    this.setState({
      visible_upload: false,
    });

    console.log(this.state);

    const formData = new FormData();

    formData.append("file", this.state.files);

    axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/blog-image/register`,
        formData
      )
      .then((res) => {
        const data = res.data;
        this.setState({
          files: [],
        });
        if (this.state.data) {
          message.success("Image Added Successfully", 5);
        }
        window.location.reload(false);
      })
      .catch(function (error) {
        console.log(error);
        message.error("Error In Uploading Image");
      })
      .catch(function (error) {
        console.log(error);
        message.error("Error In Uploading Image");
      });
  };

  componentDidMount() {
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/blog/all-blog`)
      .then((response) => {
        var blogData = response.data.data;
        var bData = blogData.reverse();
        this.setState({ dataBlog: bData });
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/blog-image/all-images`)
      .then((response) => {
        var imgdata = response.data.data;
        console.log("image", imgdata);
        this.setState({ imgdata: imgdata });
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });
  }

  // post req
  postBlogApi = (blogData) => {
    console.log("post blog", blogData);
    if (this.state.title != "") {
      if (this.state.featuredImg != "") {
        axios
          .post(`${process.env.REACT_APP_API_URL}/api/blog/register`, {
            data: blogData,
            title: this.state.title,
            featuredImg: this.state.featuredImg
          })
          .then((res) => {
            const data = res.blogData;
            this.setState({
              blogData,
            });
            if (this.state.blogData) {
              message.success("Blog Added Successfully");
            }
            window.location.reload(false);
          })
          .catch(function (error) {
            console.log(error);
            message.error("Error In Uploading Blog");
          });
      }
      else {
        message.error("Featured Image missing")
      }
    }
    else {
      message.error("Missing Title")
    }

  };
  deleteBlog = (blogID) => {
    console.log("blog deleted");
    axios
      .delete(`${process.env.REACT_APP_API_URL}/api/blog/${blogID}`, {})
      .then((res) => {
        message.success("Blog Deleted Successfully");

        window.location.reload(false);
      })
      .catch(function (error) {
        console.log(error);
        message.error("Error In Deleting Blog");
      });
  };
  deleteImage = (imageId) => {
    console.log("Image deleted");
    axios
      .delete(`${process.env.REACT_APP_API_URL}/api/blog-image/${imageId}`, {})
      .then((res) => {
        message.success("Image Deleted Successfully");

        window.location.reload(false);
      })
      .catch(function (error) {
        console.log(error);
        message.error("Error In Deleting Image");
      });
  };
  passImageUrl = (imagelink) => {
    // this.setState({
    //   ckdata: `<img src="${imagelink}" />`,
    //   visible_image: false,
    // });
    this.setState({ featuredImg: imagelink, visible_image: false, })
  };
  onChangeFile(e) {
    let files = e.target.files;
    this.setState({ files: files[0] });
    console.log(files[0]);
    console.log(this.state.files);
  }
  render() {
    const { dataBlog, imgdata } = this.state;

    return (
      <>
        <div className="App">
          <Row>
            <Col span={18}>
              <h3>Title</h3>
              <div style={{ margin: '10px 0' }}>
                <input placeholder="Title" style={{ width: '100%' }} value={this.state.title} onChange={(e) => { this.setState({ title: e.target.value }) }} />
              </div>
            </Col>
            <Col span={6}>
              <div style={{ margin: '10px 0', textAlign: 'center' }}>
                <h3>
                  Featured Image
                </h3>
                {this.state.featuredImg != "" ? <img width={100} src={this.state.featuredImg} alt="blog-pic" /> : null}
              </div>
            </Col>

            <Col span={18}>
              <CKEditor
                editor={ClassicEditor}
                data={this.state.ckdata}
                onInit={(editor) => {
                  // You can store the "editor" and use when it is needed.
                  console.log("Editor is ready to use!", editor);
                }}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  console.log({ event, editor, data });
                  this.setState({ blogData: data });
                }}
                onBlur={(event, editor) => {
                  console.log("Blur.", editor);
                }}
                onFocus={(event, editor) => {
                  console.log("Focus.", editor);
                }}
              />

              <Button
                className="mt-2"
                type="primary"
                onClick={() => this.postBlogApi(this.state.blogData)}
                style={{
                  width: "15%",
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
              >
                Publish
              </Button>

            </Col>

            <Col span={6}>
              <Card title="Upload Image" style={{ marginLeft: 10 }}>
                <Button
                  className="mt-2"
                  type="primary"
                  onClick={this.showModalImage}
                  style={{
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                >
                  Gallery
                </Button>
                <Button
                  type="primary"
                  onClick={this.showModalUpload}
                  style={{
                    marginLeft: "2px",
                    marginRight: "auto",
                  }}
                >
                  Upload
                </Button>
              </Card>
            </Col>
          </Row>
        </div>
        <div style={{ fontSize: 40 }} className="mt-2">
          Recent Blogs
        </div>
        {dataBlog.map((blog) => (
          <Card style={{ margin: 10 }}>
            <div>
              <Tabs onChange={callback} type="card">
                <TabPane tab="Blog" key="1">
                  <div dangerouslySetInnerHTML={{ __html: blog.data }} />
                </TabPane>
                <TabPane tab="Details" key="2">
                  <p>id: {blog._id}</p>
                  <p>Created At: {blog.createdAt}</p>

                  <Button
                    type="primary"
                    danger
                    style={{}}
                    onClick={this.showModal}
                  >
                    Delete
                  </Button>
                </TabPane>
              </Tabs>
            </div>

            <Modal
              title="Basic Modal"
              visible={this.state.visible}
              onOk={() => this.deleteBlog(blog._id)}
              onCancel={this.handleCancelModal}
            >
              <p> Are you sure you want to delete this blog ?</p>
            </Modal>
          </Card>
        ))}
        <Modal
          width="520"
          title="Blog Gallery"
          visible={this.state.visible_image}
          footer={null}
          onCancel={this.handleCancelModal}
        >
          <Row>
            {imgdata.map((image) => (
              <Col span={6}>
                <Row>
                  <img style={{ width: 280 }} src={image.imageURL}></img>
                </Row>
                <Row>
                  <Button
                    type="primary"
                    danger
                    style={{}}
                    onClick={() => this.deleteImage(image._id)}
                  >
                    Delete
                  </Button>
                  <Button
                    type="primary"
                    style={{}}
                    onClick={() => this.passImageUrl(image.imageURL)}
                  >
                    Select
                  </Button>
                </Row>
              </Col>
            ))}
          </Row>
        </Modal>
        <Modal
          style={{}}
          title="Upload Image"
          visible={this.state.visible_upload}
          onOk={this.uploadFormDetails}
          onCancel={this.handleCancelModal}
        >
          <Form
            name="basic"
            initialValues={{
              remember: true,
            }}
          >
            <Form.Item
              name="upload"
              label="Image"
              rules={[{ required: true }]}
              valuePropName="fileList"
            >
              <input
                style={{ margin: "10px" }}
                type="file"
                name="img"
                id="img"
                onChange={(e) => this.onChangeFile(e)}
              />
            </Form.Item>
          </Form>
        </Modal>
      </>
    );
  }
}

export default Ck;
