import React from "react";
import {
  Table,
  Tag,
  Space,
  message,
  Skeleton,
  Button,
  Modal,
  Card,
} from "antd";
import axios from "axios";
import "./style/User.css";

class User extends React.Component {
  state = {
    data: {},
    user: [],
    loading: true,
    visible_user_modal: false,
    modal_name: "",
    modal_id: "",
    modal_email: "",
    modal_createdAt: "",
    modal_updatedAt: "",
  };

  columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Id",
      dataIndex: "_id",
      key: "_id",
    },
    {
      title: "Created On",
      dataIndex: "createdAt",
      key: "createdAt",
    },
    {
      title: "Updated On",
      dataIndex: "updatedAt",
      key: "updatedAt",
    },
    {
      title: "Action",
      key: "action",
      render: () => (
        <Button>
          <a>More Details</a>
        </Button>
      ),
    },
  ];

  showUserModal(record) {
    this.setState({
      visible_user_modal: true,
      modal_name: record.name,
      modal_id: record._id,
      modal_email: record.email,
      modal_createdAt: record.createdAt,
      modal_updatedAt: record.updatedAt,
    });

    // console.log(_id);
  }

  handleDetailsCancel = (e) => {
    console.log(e);
    this.setState({
      visible_user_modal: false,
    });
  };

  componentDidMount() {
    const user = {
      name: this.state.data,
    };
    axios
      .post(`${process.env.REACT_APP_API_URL}/api/auth/all-user`, {
        user,
      })
      .then((res) => {
        const data = res.data;
        const user = data.data;
        this.setState({ data, user, loading: false });
        console.log(this.state.user);
      });
  }

  renderSkeleton() {
    return <Skeleton loading={this.state.loading} active />;
  }

  render() {
    {
      console.log(this.state);
    }
    const TabularData = () => {
      return (
        <Table
          onRow={(record, rowIndex) => {
            return {
              onClick: () => {
                this.showUserModal(record);
              },
            };
          }}
          columns={this.columns}
          dataSource={this.state.user}
        />
      );
    };
    return (
      <>
        {this.state.loading ? this.renderSkeleton() : TabularData()}
        <Modal
          title="User Details"
          centered
          width={1400}
          visible={this.state.visible_user_modal}
          onCancel={this.handleDetailsCancel}
          className="user-modal"
          footer={[null]}
        >
          <div class="card-content">
            <div class="image">
              <img
                class="user-image"
                src="https://img.icons8.com/plasticine/2x/user.png"
              />
            </div>
            <div class="user-details">
              <div class="name">{this.state.modal_name}</div>
              <div class="data mt-2">
                <span class="card-label-sml">Email: </span>
                {this.state.modal_email}
              </div>
              <div className="data mt-2">
                <span class="card-label-sml">User Id: </span>{" "}
                {this.state.modal_id}
              </div>
            </div>
            <div class="user-details2">
              <div class="data">
                <span class="card-label-sml">Created At: </span>
                {this.state.modal_createdAt}
              </div>
              <div class="data mt-2">
                <span class="card-label-sml">Updated At: </span>
                {this.state.modal_updatedAt}
              </div>
              <div class="data mt-2">
                <span class="card-label-sml">No. of Orders: </span>4
              </div>
            </div>
          </div>
        </Modal>
      </>
    );
  }
}

export default User;
