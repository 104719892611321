import React from "react";
import {
  Card,
  Col,
  Row,
  message,
  Button,
  Input,
  Modal,
  List,
  Tag,
  Divider,
  InputNumber,
} from "antd";
import axios from "axios";
import { Chart } from "react-google-charts";
import PriceTable from "./LivePricesTable";
import "./styles/LivePrices.css";

class LivePrices extends React.Component {
  state = {
    silvermakingchargedata: {},
    silvermakingcharge: {},
    goldmakingchargedata: {},
    goldmakingcharge: {},
    updatedgoldmakingcharge_onethousand: undefined,
    updatedgoldmakingcharge_fivehundred: undefined,
    updatedgoldmakingcharge_twohundredfifty: undefined,
    updatedgoldmakingcharge_onehundred: undefined,
    updatedgoldmakingcharge_fifty: undefined,
    updatedgoldmakingcharge_twenty: undefined,
    updatedgoldmakingcharge_ten: undefined,
    updatedgoldmakingcharge_five: undefined,
    updatedgoldmakingcharge_one: undefined,
    updatedsilvermakingcharge_onethousand: undefined,
    updatedsilvermakingcharge_fivehundred: undefined,
    updatedsilvermakingcharge_twohundredfifty: undefined,
    updatedsilvermakingcharge_onehundred: undefined,
    updatedsilvermakingcharge_fifty: undefined,
    updatdsilvermakingcharge_twenty: undefined,
    updatedsilvermakingcharge_ten: undefined,
    updatedsilvermakingcharge_five: undefined,
    updatedsilvermakingcharge_one: undefined,
    goldpricedata: [],
    silverpricedata: [],
    metalprice: [],
    allgoldpricedata: [],
    currentgoldpriceeuro: "",
    currentgoldpricedollar: "",
    currentpriceofsilvereuro: "",
    currentpriceofsilverdollar: "",
    chartdata: [],
    previousgoldprice: "",
    previouspriceofsilver: "",
    loading: true,
    goldInput: undefined,
    silverInput: undefined,
    visible_prices: false,

    metal_type: "",
    formSubmit: false,
    data: {},
    goldPriceHistory: [],
    silverPriceHistory: [],
    goldPrice1: undefined,
    goldPrice2: undefined,
    goldPrice3: undefined,
    silverPrice1: undefined,
    silverPrice2: undefined,
    silverPrice3: undefined,
    goldDate1: "",
    silverDate1: "",
    goldInputAdditional: "",
    goldAdditionalPriceHistory: "",
    silverAdditionalInput: "",
    silverAdditionalPriceHistory: "",
  };

  showPriceModal = (type) => {
    this.setState({
      visible_prices: true,
      metal_type: type,
    });
  };
  showAdditionalPriceModal = (type) => {
    this.setState({
      visible_additional_prices: true,
      metal_type: type,
    });
  };
  showAdditionalSilverPriceModal = (type) => {
    this.setState({
      visible_additionalSilver_prices: true,
      metal_type: type,
    });
  };
  uploadadditionalgoldFormDetails = (e) => {
    const {
      updatedgoldmakingcharge_onethousand,
      updatedgoldmakingcharge_fivehundred,
      updatedgoldmakingcharge_twohundredfifty,
      updatedgoldmakingcharge_onehundred,
      updatedgoldmakingcharge_fifty,
      updatedgoldmakingcharge_twenty,
      updatedgoldmakingcharge_ten,
      updatedgoldmakingcharge_five,
      updatedgoldmakingcharge_one,
    } = this.state;
    debugger;
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/making-charge-gold/register-making`,
        {
          onethousand: updatedgoldmakingcharge_onethousand,
          fivehundred: updatedgoldmakingcharge_fivehundred,
          twohundredfifty: updatedgoldmakingcharge_twohundredfifty,
          onehundred: updatedgoldmakingcharge_onehundred,
          fifty: updatedgoldmakingcharge_fifty,
          twenty: updatedgoldmakingcharge_twenty,
          ten: updatedgoldmakingcharge_ten,
          five: updatedgoldmakingcharge_five,
          one: updatedgoldmakingcharge_one,
        }
      )
      .then((res) => {
        this.state.data = res.data;
        if (this.state.data) {
          message.success("Gold Additional Prices Are Added Successfully", 5);
        }
        e.preventDefault();
        window.location.reload(false);
      })
      .catch(function (error) {
        console.log(error);
        message.error("Error In Updating Gold Price");
      });
  };
  uploadadditionalsilverFormDetails = (e) => {
    const {
      updatedsilvermakingcharge_onethousand,
      updatedsilvermakingcharge_fivehundred,
      updatedsilvermakingcharge_twohundredfifty,
      updatedsilvermakingcharge_onehundred,
      updatedsilvermakingcharge_fifty,
      updatedsilvermakingcharge_twenty,

      updatedsilvermakingcharge_ten,
      updatedsilvermakingcharge_five,
      updatedsilvermakingcharge_one,
    } = this.state;
    debugger;
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/making-charge-silver/register-making`,
        {
          onethousand: updatedsilvermakingcharge_onethousand,
          fivehundred: updatedsilvermakingcharge_fivehundred,
          twohundredfifty: updatedsilvermakingcharge_twohundredfifty,
          onehundred: updatedsilvermakingcharge_onehundred,
          fifty: updatedsilvermakingcharge_fifty,
          twenty: updatedsilvermakingcharge_twenty,
          ten: updatedsilvermakingcharge_ten,
          five: updatedsilvermakingcharge_five,
          one: updatedsilvermakingcharge_one,
        }
      )
      .then((res) => {
        this.state.data = res.data;
        if (this.state.data) {
          message.success("Silver Additional Prices Are Added Successfully", 5);
        }
        window.location.reload(false);
        e.preventDefault();
      })
      .catch(function (error) {
        console.log(error);
        message.error("Error In Updating Silver Price");
      });
  };
  uploadFormDetails = (e) => {
    this.setState({
      visible_prices: false,
      formSubmit: true,
    });

    const { goldInput, silverInput } = this.state;

    if (goldInput) {
      axios
        .post(`${process.env.REACT_APP_API_URL}/api/gold/register`, {
          goldprice: goldInput,
        })
        .then((res) => {
          this.state.data = res.data;
          this.setState({
            goldInput: "",
          });
          if (this.state.data) {
            message.success("Gold Price Added Successfully", 5);
          }
          window.location.reload(false);
        })
        .catch(function (error) {
          console.log(error);
          message.error("Error In Updating Gold Price");
        });
    } else {
      axios
        .post(`${process.env.REACT_APP_API_URL}/api/silver/register`, {
          silverprice: silverInput,
        })
        .then((res) => {
          this.state.data = res.data;
          this.setState({
            silverInput: "",
          });
          if (this.state.data) {
            message.success("Silver Price Added Successfully", 5);
          }
          window.location.reload(false);
        })
        .catch(function (error) {
          console.log(error);
          message.error("Error In Updating Silver Price");
        });
    }
  };

  handleCancel = (e) => {
    console.log(e);
    this.setState({
      visible_prices: false,
      visible_additionalSilver_prices: false,
      visible_additional_prices: false,
    });
  };
  // POST additional price for gold
  setAdditionalPriceGold = (price) => {
    console.log("clicked", price);
    axios
      .post(`${process.env.REACT_APP_API_URL}/api/additional-gold/register`, {
        additionalprice: price,
      })
      .then((res) => {
        const data = res.additionalprice;
        this.setState({});
        if (this.state.blogData) {
          message.success("Successfull");
        }
        window.location.reload(false);
      })
      .catch(function (error) {
        console.log(error);
        message.error("Error");
      });
  };
  // POST additional price for silver
  setAdditionalPriceSilver = (price) => {
    console.log("clicked", price);
    axios
      .post(`${process.env.REACT_APP_API_URL}/api/additional-silver/register`, {
        additionalprice: price,
      })
      .then((res) => {
        const data = res.additionalprice;
        this.setState({});
        if (this.state.blogData) {
          message.success("Successfull");
        }
        window.location.reload(false);
      })
      .catch(function (error) {
        console.log(error);
        message.error("Error");
      });
  };

  componentDidMount() {
    //API for GOLD Price
    axios
      .get(`${process.env.REACT_APP_API_SPOT_GOLD_PRICE_URL}`)
      .then((response) => {
        if (response.status === 200) {
          const goldpricedata = response.data.dataset;
          const allgoldpricedata = goldpricedata.data;
          const currentgoldpriceeuro = goldpricedata.data[0][5];
          const currentgoldpricedollar = goldpricedata.data[0][1];
          const previousgoldprice = goldpricedata.data[1][5];
          console.log("gold data", goldpricedata);
          this.setState({
            goldpricedata,
            allgoldpricedata,
            currentgoldpriceeuro,
            currentgoldpricedollar,
            previousgoldprice,
          });
          this.getChartdata();
        }
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });
    // API for Silver Price
    axios
      .get(`${process.env.REACT_APP_API_SPOT_SILVER_PRICE_URL}`)
      .then((response) => {
        const silverpricedata = response.data.dataset;
        const allsilverpricedata = silverpricedata.data;
        const currentpriceofsilvereuro = silverpricedata.data[0][3];
        const currentpriceofsilverdollar = silverpricedata.data[0][1];
        const previouspriceofsilver = silverpricedata.data[1][3];
        this.setState({
          silverpricedata,
          allsilverpricedata,
          currentpriceofsilvereuro,
          currentpriceofsilverdollar,
          previouspriceofsilver,
        });
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });

    //API TO GET GOLD PRICE HISTORY
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/gold/all-gold`)
      .then((response) => {
        const goldPriceHistory = response.data.data.reverse();
        const goldPrice1 = goldPriceHistory[0].goldprice;
        const goldPrice2 = goldPriceHistory[1].goldprice;
        const goldPrice3 = goldPriceHistory[2].goldprice;
        const goldDate1 = goldPriceHistory[0].updatedAt;
        this.setState({
          goldPriceHistory,
          goldPrice1,
          goldPrice2,
          goldPrice3,
          goldDate1,
        });
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });
    //API TO GET GOLD Additional PRICE HISTORY
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/additional-gold/all`)
      .then((response) => {
        const goldAdditionalPriceHistory = response.data.data.reverse();
        console.log("additional Price", goldAdditionalPriceHistory);
        const goldAdditionalPrice1 =
          goldAdditionalPriceHistory[0].additionalprice;
        const goldAdditionalPrice2 =
          goldAdditionalPriceHistory[1].additionalprice;
        const goldAdditionalPrice3 =
          goldAdditionalPriceHistory[2].additionalprice;
        const goldAdditionalDate1 = goldAdditionalPriceHistory[0].updatedAt;
        console.log("additional Price", goldAdditionalPrice1);
        this.setState({
          goldAdditionalPriceHistory,
          goldAdditionalPrice1,
          goldAdditionalPrice2,
          goldAdditionalPrice3,
          goldAdditionalDate1,
        });
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });
    //API TO GET SILVER Additional PRICE HISTORY
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/additional-silver/all`)
      .then((response) => {
        const silverAdditionalPriceHistory = response.data.data.reverse();
        console.log("additional Price", silverAdditionalPriceHistory);
        const silverAdditionalPrice1 =
          silverAdditionalPriceHistory[0].additionalprice;
        const silverAdditionalPrice2 =
          silverAdditionalPriceHistory[1].additionalprice;
        const silverAdditionalPrice3 =
          silverAdditionalPriceHistory[2].additionalprice;
        const silverAdditionalDate1 = silverAdditionalPriceHistory[0].updatedAt;
        console.log("additional silver Price", silverAdditionalPrice1);
        this.setState({
          silverAdditionalPriceHistory,
          silverAdditionalPrice1,
          silverAdditionalPrice2,
          silverAdditionalPrice3,
          silverAdditionalDate1,
        });
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });

    //API TO GET SILVER PRICE HISTORY
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/silver/all-silver`)
      .then((response) => {
        const silverPriceHistory = response.data.data.reverse();
        const silverPrice1 = silverPriceHistory[0].silverprice;
        const silverPrice2 = silverPriceHistory[1].silverprice;
        const silverPrice3 = silverPriceHistory[2].silverprice;
        const silverDate1 = silverPriceHistory[0].updatedAt;

        this.setState({
          silverPriceHistory,
          silverPrice1,
          silverPrice2,
          silverPrice3,
          silverDate1,
        });
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });
    // API for making charges for gold
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/making-charge-gold/all-making-gold`
      )
      .then((response) => {
        var goldmakingchargedata = response.data.data;
        goldmakingchargedata.reverse();
        const goldmakingcharge = goldmakingchargedata[0];
        this.setState({ goldmakingchargedata, goldmakingcharge });
        console.log("gold making charge", this.state.goldmakingcharge);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });
    // API for making charges for silver
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/making-charge-silver/all-making-silver`
      )
      .then((response) => {
        var silvermakingchargedata = response.data.data;
        silvermakingchargedata.reverse();
        const silvermakingcharge = silvermakingchargedata[0];
        this.setState({ silvermakingchargedata, silvermakingcharge });
        console.log("gold making charge", this.state.silvermakingcharge);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });
  }
  getChartdata = () => {
    var chartdata = [[{ type: "string", label: "Day" }, "Gold", "Silver"]];
    var chartdataGold = [["Date", "Gold"]];
    var chartdataSilver = [["Date", "Silver"]];
    for (var i = 7; i >= 0; i--) {
      var date = this.state.allgoldpricedata[i][0];
      chartdata.push([
        date.substring(0),
        this.state.allgoldpricedata[i][5],
        this.state.allsilverpricedata[i][3],
      ]);
      chartdataGold.push([
        date.substring(8),
        this.state.allgoldpricedata[i][5],
      ]);
      chartdataSilver.push([
        date.substring(8),
        this.state.allsilverpricedata[i][3],
      ]);
    }
    this.setState({ chartdata, chartdataGold, chartdataSilver });
    this.setState({ loading: false });
  };

  renderHistory(metal) {
    const {
      goldPrice1,
      goldPrice2,
      goldPrice3,
      silverPrice1,
      silverPrice2,
      silverPrice3,
    } = this.state;

    if (metal === "gold") {
      return (
        <>
          <Tag
            className="gold-tag"
            style={{ fontSize: "20px", padding: "5px" }}
            color="#eade9b"
          >
            Gold
          </Tag>
          <ol className="price-history">
            <li>{goldPrice1}€</li>
            <li>{goldPrice2}€</li>
            <li>{goldPrice3}€</li>
          </ol>
        </>
      );
    } else {
      return (
        <>
          <Tag
            className="silver-tag"
            style={{ fontSize: "20px", padding: "5px" }}
          >
            Silver
          </Tag>
          <ol className="price-history">
            <li>{silverPrice1}€</li>
            <li>{silverPrice2}€</li>
            <li>{silverPrice3}€</li>
          </ol>
        </>
      );
    }
  }
  renderAdditionalHistory(metal) {
    const {
      goldAdditionalPrice1,
      goldAdditionalPrice2,
      goldAdditionalPrice3,
      silverAdditionalPrice1,
      silverAdditionalPrice2,
      silverAdditionalPrice3,
    } = this.state;

    if (metal === "gold") {
      return (
        <>
          <Tag
            className="gold-tag"
            style={{ fontSize: "20px", padding: "5px" }}
            color="#eade9b"
          >
            Gold
          </Tag>
          <ol className="price-history">
            <li>{goldAdditionalPrice1}€</li>
            <li>{goldAdditionalPrice2}€</li>
            <li>{goldAdditionalPrice3}€</li>
          </ol>
        </>
      );
    } else {
      return (
        <>
          <Tag
            className="silver-tag"
            style={{ fontSize: "20px", padding: "5px" }}
          >
            Silver
          </Tag>
          <ol className="price-history">
            <li>{silverAdditionalPrice1}€</li>
            <li>{silverAdditionalPrice2}€</li>
            <li>{silverAdditionalPrice3}€</li>
          </ol>
        </>
      );
    }
  }

  render() {
    const goldHistory = this.state.goldPriceHistory;
    return (
      <div className="site-card-wrapper">
        <h1 className="live-price-heading">Gold</h1>
        <Row gutter={16}>
          <Col span={8}>
            <Card
              title="Gold Live Price History"
              bordered={false}
              style={{ height: "100%" }}
            >
              <Row>
                <Col span={24}> {this.renderHistory("gold")}</Col>
              </Row>
            </Card>
          </Col>
          <Col span={8}>
            <Card
              title="Set Price (€)"
              bordered={false}
              style={{ height: "100%" }}
            >
              <p className="set-price-tag">Gold</p>
              <p>Update Price €/gm</p>
              <p>
                <Input
                  value={this.state.goldInput}
                  onChange={(e) => this.setState({ goldInput: e.target.value })}
                  placeholder="Gold Price"
                  style={{ width: 200 }}
                />
                <Button
                  onClick={() => this.showPriceModal("gold")}
                  type="primary"
                  style={{ marginLeft: 20 }}
                >
                  Update
                </Button>
              </p>
            </Card>
          </Col>
          <Col span={8}>
            <Card title="Current Price" bordered={false}>
              <div className="text-center">
                <img
                  className="spot-prices-img"
                  src={require("../images/gold-bar-overview.png")}
                ></img>
                <img
                  className="spot-prices-img"
                  src={require("../images/au.png")}
                ></img>
              </div>
              <p className="spot-prices">
                Gold Current Price:
                <b className="current-price">{this.state.goldPrice1}€/g</b>
              </p>
              <p className="date">
                <span>Updated At:</span> {this.state.goldDate1}
              </p>
            </Card>
          </Col>
        </Row>
        {/* Additional charges Gold */}
        <h1 className="live-price-heading">Gold Additional Charges</h1>
        <Row gutter={16}>
          <Col span={8}>
            <Card
              title="Gold Additional Price History"
              bordered={false}
              style={{ height: "100%" }}
            >
              <Row>
                <Col span={24}> {this.renderAdditionalHistory("gold")}</Col>
              </Row>
            </Card>
          </Col>
          <Col span={8}>
            <Card
              title="Set Additional Price (€)"
              bordered={false}
              style={{ height: "100%" }}
            >
              <p className="set-price-tag">Gold</p>
              <p>Update Price €/gm</p>
              <p>
                <Input
                  value={this.state.goldInputAdditional}
                  onChange={(e) =>
                    this.setState({ goldInputAdditional: e.target.value })
                  }
                  placeholder="Gold Price"
                  style={{ width: 200 }}
                />
                <Button
                  onClick={() =>
                    this.setAdditionalPriceGold(this.state.goldInputAdditional)
                  }
                  type="primary"
                  style={{ marginLeft: 20 }}
                >
                  Update
                </Button>
              </p>
            </Card>
          </Col>
          <Col span={8}>
            <Card title="Current Additional Price" bordered={false}>
              <div className="text-center">
                <img
                  className="spot-prices-img"
                  src={require("../images/gold-bar-overview.png")}
                ></img>
                <img
                  className="spot-prices-img"
                  src={require("../images/au.png")}
                ></img>
              </div>
              <p className="spot-prices">
                Gold Current Additional Price:
                <b className="current-price">
                  {this.state.goldAdditionalPrice1}€/g
                </b>
              </p>
              <p className="date">
                <span>Updated At:</span> {this.state.goldAdditionalDate1}
              </p>
            </Card>
          </Col>
        </Row>

        <h1 className="live-price-heading">Gold Making Charges</h1>
        <Row gutter={16}>
          <Col span={16}>
            <Card title="Gold Making Charges" bordered={false}>
              <Row>
                <Col span={24}>
                  <div className="making-charges">
                    <table>
                      <tr>
                        <th>Weight</th>
                        <th>Making/Packing Charges</th>
                        <th>Updated At</th>
                      </tr>
                      <tr>
                        <td>
                          <Tag color="blue">1 kg</Tag>
                        </td>
                        <td>{this.state.goldmakingcharge.onethousand} € </td>
                        <td>{this.state.goldmakingcharge.createdAt}</td>
                      </tr>
                      <tr>
                        <td>
                          <Tag color="blue">500 gm</Tag>
                        </td>
                        <td>{this.state.goldmakingcharge.fivehundred} € </td>
                        <td>{this.state.goldmakingcharge.createdAt}</td>
                      </tr>
                      <tr>
                        <td>
                          <Tag color="blue">250 gm</Tag>
                        </td>
                        <td>{this.state.goldmakingcharge.twohundredfifty} €</td>
                        <td>{this.state.goldmakingcharge.createdAt}</td>
                      </tr>
                      <tr>
                        <td>
                          <Tag color="blue">100 gm</Tag>
                        </td>
                        <td>{this.state.goldmakingcharge.onehundred} €</td>
                        <td>{this.state.goldmakingcharge.createdAt}</td>
                      </tr>
                      <tr>
                        <td>
                          <Tag color="blue">50 gm</Tag>
                        </td>
                        <td>{this.state.goldmakingcharge.fifty} €</td>
                        <td>{this.state.goldmakingcharge.createdAt}</td>
                      </tr>
                      <tr>
                        <td>
                          <Tag color="blue">20 gm</Tag>
                        </td>
                        <td>{this.state.goldmakingcharge.twenty} €</td>
                        <td>{this.state.goldmakingcharge.createdAt}</td>
                      </tr>
                      <tr>
                        <td>
                          <Tag color="blue">10 gm</Tag>
                        </td>
                        <td>{this.state.goldmakingcharge.ten} €</td>
                        <td>{this.state.goldmakingcharge.createdAt}</td>
                      </tr>
                      <tr>
                        <td>
                          <Tag color="blue">5 gm</Tag>
                        </td>
                        <td>{this.state.goldmakingcharge.five} €</td>
                        <td>{this.state.goldmakingcharge.createdAt}</td>
                      </tr>
                      <tr>
                        <td>
                          <Tag color="blue">1 gm</Tag>
                        </td>
                        <td>{this.state.goldmakingcharge.one} €</td>
                        <td>{this.state.goldmakingcharge.createdAt}</td>
                      </tr>
                    </table>
                  </div>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col span={8}>
            <Card
              title="Update Making Charges"
              bordered={false}
              style={{ height: "100%" }}
            >
              <div style={{ marginTop: "25%", marginBottom: "auto" }}>
                <Row>
                  <h1>
                    Updating Making charges on refferencing weight effects price
                    caluculation for each product.
                  </h1>
                  <Button
                    onClick={() => this.showAdditionalPriceModal("silver")}
                    type="primary"
                  >
                    Update
                  </Button>
                </Row>
              </div>
            </Card>
          </Col>
        </Row>

        <h1 className="live-price-heading">Silver</h1>
        <Row gutter={16}>
          <Col span={8}>
            <Card
              title="Silver Live Price History"
              bordered={false}
              style={{ height: "100%" }}
            // style={{ width: 350, height: 300, marginLeft: 50 }}
            >
              <Row>
                <Col span={12}> {this.renderHistory("silver")}</Col>
              </Row>
            </Card>
          </Col>
          <Col span={8}>
            <Card
              title="Set Price (€)"
              bordered={false}
              style={{ height: "100%" }}
            >
              <p className="set-price-tag">Silver</p>
              <p>Update Price €/gm</p>
              <p>
                <Input
                  value={this.state.silverInput}
                  onChange={(e) =>
                    this.setState({ silverInput: e.target.value })
                  }
                  placeholder="Silver Price"
                  style={{ width: 200 }}
                />
                <Button
                  onClick={() => this.showPriceModal("silver")}
                  type="primary"
                  style={{ marginLeft: 20 }}
                >
                  Update
                </Button>
              </p>
            </Card>
          </Col>
          <Col span={8}>
            <Card title="Current Price" bordered={false}>
              <div className="text-center">
                <img
                  className="spot-prices-img"
                  src={require("../images/silver-bar-overview.png")}
                ></img>
                <img
                  className="spot-prices-img"
                  src={require("../images/ag.png")}
                ></img>
              </div>
              <p className="spot-prices">
                Silver Current Price:
                <b className="current-price">{this.state.silverPrice1}€/g</b>
              </p>
              <p className="date">
                <span>Updated At:</span> {this.state.silverDate1}
              </p>
            </Card>
          </Col>
        </Row>
        {/* silver additional prices */}
        <h1 className="live-price-heading">Silver Additional Prices</h1>
        <Row gutter={16}>
          <Col span={8}>
            <Card
              title="Silver Additional Price History"
              bordered={false}
              style={{ height: "100%" }}
            // style={{ width: 350, height: 300, marginLeft: 50 }}
            >
              <Row>
                <Col span={12}> {this.renderAdditionalHistory("silver")}</Col>
              </Row>
            </Card>
          </Col>
          <Col span={8}>
            <Card
              title="Set Additional Price (€)"
              bordered={false}
              style={{ height: "100%" }}
            >
              <p className="set-price-tag">Silver</p>
              <p>Update Additional Price €/gm</p>
              <p>
                <Input
                  value={this.state.silverAdditionalInput}
                  onChange={(e) =>
                    this.setState({ silverAdditionalInput: e.target.value })
                  }
                  placeholder="Silver Price"
                  style={{ width: 200 }}
                />
                <Button
                  onClick={() =>
                    this.setAdditionalPriceSilver(
                      this.state.silverAdditionalInput
                    )
                  }
                  type="primary"
                  style={{ marginLeft: 20 }}
                >
                  Update
                </Button>
              </p>
            </Card>
          </Col>
          <Col span={8}>
            <Card title="Current Additional Price" bordered={false}>
              <div className="text-center">
                <img
                  className="spot-prices-img"
                  src={require("../images/silver-bar-overview.png")}
                ></img>
                <img
                  className="spot-prices-img"
                  src={require("../images/ag.png")}
                ></img>
              </div>
              <p className="spot-prices">
                Silver Current Additional Price:
                <b className="current-price">
                  {this.state.silverAdditionalPrice1}€/g
                </b>
              </p>
              <p className="date">
                <span>Updated At:</span> {this.state.silverAdditionalDate1}
              </p>
            </Card>
          </Col>
        </Row>
        <h1 className="live-price-heading">Silver Making Prices</h1>
        <Row gutter={16}>
          <Col span={16}>
            <Card title="Silver Making Charges" bordered={false}>
              <Row>
                <Col span={24}>
                  <div className="making-charges">
                    <table>
                      <tr>
                        <th>Weight</th>
                        <th>Making/Packing Charges</th>
                        <th>Updated At</th>
                      </tr>
                      <tr>
                        <td>
                          <Tag color="blue">1 kg</Tag>
                        </td>
                        <td>{this.state.silvermakingcharge.onethousand} € </td>
                        <td>{this.state.silvermakingcharge.createdAt}</td>
                      </tr>
                      <tr>
                        <td>
                          <Tag color="blue">500 gm</Tag>
                        </td>
                        <td>{this.state.silvermakingcharge.fivehundred} € </td>
                        <td>{this.state.silvermakingcharge.createdAt}</td>
                      </tr>
                      <tr>
                        <td>
                          <Tag color="blue">250 gm</Tag>
                        </td>
                        <td>
                          {this.state.silvermakingcharge.twohundredfifty} €
                        </td>
                        <td>{this.state.silvermakingcharge.createdAt}</td>
                      </tr>
                      <tr>
                        <td>
                          <Tag color="blue">100 gm</Tag>
                        </td>
                        <td>{this.state.silvermakingcharge.onehundred} €</td>
                        <td>{this.state.silvermakingcharge.createdAt}</td>
                      </tr>
                      <tr>
                        <td>
                          <Tag color="blue">50 gm</Tag>
                        </td>
                        <td>{this.state.silvermakingcharge.fifty} €</td>
                        <td>{this.state.silvermakingcharge.createdAt}</td>
                      </tr>
                      <tr>
                        <td>
                          <Tag color="blue">20 gm</Tag>
                        </td>
                        <td>{this.state.silvermakingcharge.twenty} €</td>
                        <td>{this.state.silvermakingcharge.createdAt}</td>
                      </tr>
                      <tr>
                        <td>
                          <Tag color="blue">10 gm</Tag>
                        </td>
                        <td>{this.state.silvermakingcharge.ten} €</td>
                        <td>{this.state.silvermakingcharge.createdAt}</td>
                      </tr>
                      <tr>
                        <td>
                          <Tag color="blue">5 gm</Tag>
                        </td>
                        <td>{this.state.silvermakingcharge.five} €</td>
                        <td>{this.state.silvermakingcharge.createdAt}</td>
                      </tr>
                      <tr>
                        <td>
                          <Tag color="blue">1 gm</Tag>
                        </td>
                        <td>{this.state.silvermakingcharge.one} €</td>
                        <td>{this.state.silvermakingcharge.createdAt}</td>
                      </tr>
                    </table>
                  </div>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col span={8}>
            <Card
              title="Update Making Charges"
              bordered={false}
              style={{ height: "100%" }}
            >
              <div style={{ marginTop: "25%", marginBottom: "auto" }}>
                <Row>
                  <h1>
                    Updating Making charges on refferencing weight effects price
                    caluculation for each product
                  </h1>
                  <Button
                    onClick={() =>
                      this.showAdditionalSilverPriceModal("silver")
                    }
                    type="primary"
                  >
                    Update
                  </Button>
                </Row>
              </div>
            </Card>
          </Col>
        </Row>

        <h1 className="live-price-heading">Spot Prices</h1>
        <Row gutter={16}>
          <Col span={6}>
            <Card title="Spot Prices" bordered={false}>
              <p className="spot-prices">
                <span>Gold:</span>
                <br />
                {this.state.currentgoldpriceeuro} €/oz
                <br />
                {this.state.currentgoldpricedollar} $/oz
              </p>

              <p className="spot-prices">
                <span>Silver:</span>
                <br />
                {this.state.currentpriceofsilvereuro} €/oz
                <br />
                {this.state.currentpriceofsilverdollar} $/oz
              </p>
            </Card>
          </Col>

          <Col span={9}>
            <Card
              title="Spot Price Graph"
              bordered={false}
              style={{ height: "100%" }}
            >
              <Chart
                width={"320"}
                height={"320"}
                chartType="LineChart"
                loader={<div>Loading Chart</div>}
                data={this.state.chartdataGold}
                options={{
                  title: "Prices of Gold",
                  hAxis: {
                    title: "Date",
                  },
                  vAxis: {
                    title: "Price",
                  },
                  series: {
                    0: { color: "#c0b48b" },
                  },
                  chart: {
                    title: "Prices of Gold",
                  },
                }}
                rootProps={{ "data-testid": "1" }}
              />
            </Card>
          </Col>
          <Col span={9}>
            <Card
              title="Spot Price Graph"
              bordered={false}
              style={{ height: "100%" }}
            >
              <Chart
                width={"320"}
                height={"320"}
                chartType="LineChart"
                loader={<div>Loading Chart</div>}
                data={this.state.chartdataSilver}
                options={{
                  title: "Prices of Silver",
                  hAxis: {
                    title: "Date",
                  },
                  vAxis: {
                    title: "Price",
                  },
                  series: {
                    0: { color: "#C0C0C0" },
                  },
                }}
                rootProps={{ "data-testid": "1" }}
              />
            </Card>
          </Col>
        </Row>
        <Modal
          title="Price Details"
          okText={"Submit"}
          cancelText={"Back"}
          centered
          visible={this.state.visible_prices}
          onOk={this.uploadFormDetails}
          onCancel={this.handleCancel}
        >
          <PriceTable
            goldInput={this.state.goldInput}
            silverInput={this.state.silverInput}
            metal_type={this.state.metal_type}
          />
        </Modal>
        <Modal
          title="Price Details"
          okText={"Submit"}
          cancelText={"Back"}
          centered
          visible={this.state.visible_additional_prices}
          onOk={this.uploadadditionalgoldFormDetails}
          onCancel={this.handleCancel}
        >
          {/* additional gold charges will come here */}
          <div className="making-charges">
            <table>
              <tr>
                <th>Weight</th>
                <th>Making Charges</th>
              </tr>
              <tr>
                <td>
                  <Tag color="blue">1 kg</Tag>
                </td>
                <td>
                  <input
                    type="text"
                    id="1kg"
                    placeholder={this.state.goldmakingcharge.onethousand}
                    value={this.state.updatedgoldmakingcharge_onethousand}
                    onChange={(e) =>
                      this.setState({
                        updatedgoldmakingcharge_onethousand: e.target.value,
                      })
                    }
                  ></input>
                  €
                </td>
              </tr>
              <tr>
                <td>
                  <Tag color="blue">500 gm</Tag>
                </td>
                <td>
                  {" "}
                  <input
                    type="text"
                    id="500"
                    placeholder={this.state.goldmakingcharge.fivehundred}
                    value={this.state.updatedgoldmakingcharge_fivehundred}
                    onChange={(e) =>
                      this.setState({
                        updatedgoldmakingcharge_fivehundred: e.target.value,
                      })
                    }
                  ></input>
                  €{" "}
                </td>
              </tr>
              <tr>
                <td>
                  <Tag color="blue">250 gm</Tag>
                </td>
                <td>
                  {" "}
                  <input
                    type="text"
                    id="250"
                    placeholder={this.state.goldmakingcharge.twohundredfifty}
                    value={this.state.updatedgoldmakingcharge_twohundredfifty}
                    onChange={(e) =>
                      this.setState({
                        updatedgoldmakingcharge_twohundredfifty: e.target.value,
                      })
                    }
                  ></input>
                  €
                </td>
              </tr>
              <tr>
                <td>
                  <Tag color="blue">100 gm</Tag>
                </td>
                <td>
                  {" "}
                  <input
                    type="text"
                    id="100"
                    placeholder={this.state.goldmakingcharge.onehundred}
                    value={this.state.updatedgoldmakingcharge_onehundred}
                    onChange={(e) =>
                      this.setState({
                        updatedgoldmakingcharge_onehundred: e.target.value,
                      })
                    }
                  ></input>
                  €
                </td>
              </tr>
              <tr>
                <td>
                  <Tag color="blue">50 gm</Tag>
                </td>
                <td>
                  {" "}
                  <input
                    type="text"
                    id="50"
                    placeholder={this.state.goldmakingcharge.fifty}
                    value={this.state.updatedgoldmakingcharge_fifty}
                    onChange={(e) =>
                      this.setState({
                        updatedgoldmakingcharge_fifty: e.target.value,
                      })
                    }
                  ></input>
                  €
                </td>
              </tr>
              <tr>
                <td>
                  <Tag color="blue">20 gm</Tag>
                </td>
                <td>
                  {" "}
                  <input
                    type="text"
                    id="50"
                    placeholder={this.state.goldmakingcharge.twenty}
                    value={this.state.updatedgoldmakingcharge_twenty}
                    onChange={(e) =>
                      this.setState({
                        updatedgoldmakingcharge_twenty: e.target.value,
                      })
                    }
                  ></input>
                  €
                </td>
              </tr>
              <tr>
                <td>
                  <Tag color="blue">10 gm</Tag>
                </td>
                <td>
                  {" "}
                  <input
                    type="text"
                    id="500"
                    placeholder={this.state.goldmakingcharge.ten}
                    value={this.state.updatedgoldmakingcharge_ten}
                    onChange={(e) =>
                      this.setState({
                        updatedgoldmakingcharge_ten: e.target.value,
                      })
                    }
                  ></input>
                  €
                </td>
              </tr>
              <tr>
                <td>
                  <Tag color="blue">5 gm</Tag>
                </td>
                <td>
                  {" "}
                  <input
                    type="text"
                    id="500"
                    placeholder={this.state.goldmakingcharge.five}
                    value={this.state.updatedgoldmakingcharge_five}
                    onChange={(e) =>
                      this.setState({
                        updatedgoldmakingcharge_five: e.target.value,
                      })
                    }
                  ></input>
                  €
                </td>
              </tr>
              <tr>
                <td>
                  <Tag color="blue">1 gm</Tag>
                </td>
                <td>
                  {" "}
                  <input
                    type="text"
                    id="500"
                    placeholder={this.state.goldmakingcharge.one}
                    value={this.state.updatedgoldmakingcharge_one}
                    onChange={(e) =>
                      this.setState({
                        updatedgoldmakingcharge_one: e.target.value,
                      })
                    }
                  ></input>
                  €
                </td>
              </tr>
            </table>
          </div>
        </Modal>
        <Modal
          title="Price Details"
          okText={"Submit"}
          cancelText={"Back"}
          centered
          visible={this.state.visible_additionalSilver_prices}
          onOk={this.uploadadditionalsilverFormDetails}
          onCancel={this.handleCancel}
        >
          {/* additional silver charges will come here */}
          <div className="making-charges">
            <table>
              <tr>
                <th>Weight</th>
                <th>Making Charges</th>
              </tr>
              <tr>
                <td>
                  <Tag color="blue">1 kg</Tag>
                </td>
                <td>
                  <input
                    type="text"
                    id="1kg"
                    placeholder={this.state.silvermakingcharge.onethousand}
                    value={this.state.updatedsilvermakingcharge_onethousand}
                    onChange={(e) =>
                      this.setState({
                        updatedsilvermakingcharge_onethousand: e.target.value,
                      })
                    }
                  ></input>
                  €{" "}
                </td>
              </tr>
              <tr>
                <td>
                  <Tag color="blue">500 gm</Tag>
                </td>
                <td>
                  {" "}
                  <input
                    type="text"
                    id="500"
                    placeholder={this.state.silvermakingcharge.fivehundred}
                    value={this.state.updatedsilvermakingcharge_fivehundred}
                    onChange={(e) =>
                      this.setState({
                        updatedsilvermakingcharge_fivehundred: e.target.value,
                      })
                    }
                  ></input>
                  €{" "}
                </td>
              </tr>
              <tr>
                <td>
                  <Tag color="blue">250 gm</Tag>
                </td>
                <td>
                  {" "}
                  <input
                    type="text"
                    id="250"
                    placeholder={this.state.silvermakingcharge.twohundredfifty}
                    value={this.state.updatedsilvermakingcharge_twohundredfifty}
                    onChange={(e) =>
                      this.setState({
                        updatedsilvermakingcharge_twohundredfifty:
                          e.target.value,
                      })
                    }
                  ></input>
                  €
                </td>
              </tr>
              <tr>
                <td>
                  <Tag color="blue">100 gm</Tag>
                </td>
                <td>
                  {" "}
                  <input
                    type="text"
                    id="100"
                    placeholder={this.state.silvermakingcharge.onehundred}
                    value={this.state.updatedsilvermakingcharge_onehundred}
                    onChange={(e) =>
                      this.setState({
                        updatedsilvermakingcharge_onehundred: e.target.value,
                      })
                    }
                  ></input>
                  €
                </td>
              </tr>
              <tr>
                <td>
                  <Tag color="blue">50 gm</Tag>
                </td>
                <td>
                  {" "}
                  <input
                    type="text"
                    id="50"
                    placeholder={this.state.silvermakingcharge.fifty}
                    value={this.state.updatedsilvermakingcharge_fifty}
                    onChange={(e) =>
                      this.setState({
                        updatedsilvermakingcharge_fifty: e.target.value,
                      })
                    }
                  ></input>
                  €
                </td>
              </tr>
              <tr>
                <td>
                  <Tag color="blue">20 gm</Tag>
                </td>
                <td>
                  {" "}
                  <input
                    type="text"
                    id="20"
                    placeholder={this.state.silvermakingcharge.twenty}
                    value={this.state.updatedsilvermakingcharge_twenty}
                    onChange={(e) =>
                      this.setState({
                        updatedsilvermakingcharge_twenty: e.target.value,
                      })
                    }
                  ></input>
                  €
                </td>
              </tr>
              <tr>
                <td>
                  <Tag color="blue">10 gm</Tag>
                </td>
                <td>
                  {" "}
                  <input
                    type="text"
                    id="500"
                    placeholder={this.state.silvermakingcharge.ten}
                    value={this.state.updatedsilvermakingcharge_ten}
                    onChange={(e) =>
                      this.setState({
                        updatedsilvermakingcharge_ten: e.target.value,
                      })
                    }
                  ></input>
                  €
                </td>
              </tr>
              <tr>
                <td>
                  <Tag color="blue">5 gm</Tag>
                </td>
                <td>
                  {" "}
                  <input
                    type="text"
                    id="500"
                    placeholder={this.state.silvermakingcharge.five}
                    value={this.state.updatedsilvermakingcharge_five}
                    onChange={(e) =>
                      this.setState({
                        updatedsilvermakingcharge_five: e.target.value,
                      })
                    }
                  ></input>
                  €
                </td>
              </tr>
              <tr>
                <td>
                  <Tag color="blue">1 gm</Tag>
                </td>
                <td>
                  {" "}
                  <input
                    type="text"
                    id="500"
                    placeholder={this.state.silvermakingcharge.one}
                    value={this.state.updatedsilvermakingcharge_one}
                    onChange={(e) =>
                      this.setState({
                        updatedsilvermakingcharge_one: e.target.value,
                      })
                    }
                  ></input>
                  €
                </td>
              </tr>
            </table>
          </div>
        </Modal>
      </div>
    );
  }
}

export default LivePrices;
