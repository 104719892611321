import React from 'react'
import './App.css'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import LandingPage from './dashboard/LandingPage'
import Dashboard from './dashboard/Dashboard'
import Navigation from './dashboard/NavigationBoard'
import Blogs from './dashboard/Blog/BlogLayout'

function App() {
  return (
    <div className="App">
      <Router>
        <Switch>
          <Route exact path="/" component={LandingPage} />
          <Route path="/dashboard" component={Dashboard} />
          <Route path="/blogs" component={Blogs} />
          <Route path="/navigation" component={Navigation} />
        </Switch>
      </Router>
    </div>
  )
}

export default App
